import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkeGuidesCollectionDispatch: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Collection Items</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_dispatch.jpg"
            alt="Liberation"
          />
        </div>
        <div className="page-details">
          <h1>Collection Items - Dispatch</h1>
          <h2>
            Guide for the Collection Items system in NIKKE helping you to use
            the Dispatch feature to speed up your progress.
          </h2>
          <p>
            Last updated: <strong>24/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          Dispatch is a feature in Outpost where you can "dispatch" Nikkes on
          errands to collect items for you after a specified amount of time.
          These range from gems to core dust boxes to maintenance kits for your
          beloved dolls. The quality and number of dispatches available every
          reset depend on your Tactics Academy's development, but for veterans,
          there will be 15 in total: ten regular dispatches, four doll-oriented
          dispatches, and one favorite item dispatch. Here are some additional
          information:
        </p>
        <ul>
          <li>
            Dispatches may require one or more Nikkes of a specific rarity or
            manufacturer.
          </li>
          <li>
            Nikkes currently active on another dispatch cannot be redeployed
            until they are finished.
          </li>
          <li>
            Favorite item dispatches can only be carried out by the Nikke
            favoring them.
          </li>
          <li>
            Dispatches are automatically refreshed every day for free, reopening
            all slots.
          </li>
          <li>
            You can spend 50 Gems to reroll all unclaimed slots for better
            reward offerings. However, this does not free up slots that have
            previously been claimed, so the maximum number of dispatches will
            still be 15 regardless of rerolls.
          </li>
        </ul>
        <SectionHeader title="Reward Offerings" />
        <p>
          Below is the complete table of rewards, including their probabilities.
          This information is also available in game, but we have condensed it
          into a more readable, compact form.{' '}
        </p>
        <StaticImage
          src="../../../images/nikke/collection/dispatch_1.webp"
          alt="Guide"
        />
        <SectionHeader title="Optimization Strategy" />
        <p>
          With the recent addition of dolls to the Dispatch system, there is
          even more incentive to reset low-quality dispatches using gems. This
          has been proven to also be profitable for resources. Based on the
          probabilities listed in the game, we have created{' '}
          <a
            href="https://docs.google.com/spreadsheets/d/1t_OTPCzEklWK5OQ6YzmpnCanfUWxprEivrDUpSLKeoc/edit"
            target="_blank"
            rel="noreferrer"
          >
            another sheet
          </a>{' '}
          to calculate the cost-efficiency of rerolls with regard to the number
          of <strong>open/unclaimed</strong> slots.
        </p>
        <p>
          We did this by first calculating the average hit score of a specific
          resource type for a single open slot. Then, we compute the expected
          appearance value by multiplying the product with the number of open
          slots. Because EXP modules from Dispatch worth almost nothing and gift
          vouchers are abundant for most players, we are excluding those from
          our evaluation. In other words, we will be focusing on{' '}
          <strong>Gems, Core Dust, and Credit</strong> accumulation.
        </p>
        <p>
          Gems? Yes. They will be our monetary benchmark. In short, there is a
          chance that any open slot will reward gems. But, since we pay gems to
          reroll, we can treat them as a "rebate". With fewer open slots, your
          chance of getting a rebate will consequently diminish. So, is there a
          minimum or maximum number of open slots where refreshing becomes
          profitable?
        </p>
        <p>
          The answer is a robust <strong>YES</strong>. After doing some maths on
          our end and saving you from hours of distress, we have reached a
          definite conclusion. We will summarize the important findings here,
          but if you want the full detail, you can use our sheet, make a copy,
          and modify the numbers.
        </p>
        <ul>
          <li>
            If you refresh Dispatch when the number of unclaimed slots is
            <strong>exactly 6</strong>, you will get a similar amount of
            resources (Core Dust & Credits) as you would get from events for the
            same amount of gems. In other words, the price:return ratio value is
            superb.
          </li>
          <li>
            Using simple logic, we can also tell that{' '}
            <strong>anything below 6 will give you a worse ratio</strong>,
            whereas anything above 6 is more than excellent (and you should
            highly consider doing that).
          </li>
          <li>
            However, the rule above should only be considered after you have
            already claimed <strong>ALL</strong> gems, core dust, and credit
            boxes from the free daily refresh and gem rerolls. Ignore everything
            else.
          </li>
        </ul>
        <StaticImage
          src="../../../images/nikke/collection/dispatch_2.webp"
          alt="Guide"
        />
        <p>
          That's about it for resources. Now, doll dispatches follow the same
          concept, but since you refresh them alongside the resources above, it
          adds another value on top of it. In other words, you can get better
          doll dispatches while also benefiting from the accelerated resource
          accumulation, giving you a slight advantage versus your competitors
          (in PvP mainly).
        </p>
        <ul>
          <li>
            First of all, before you do any gem rerolls, make sure to{' '}
            <strong>claim all the good doll dispatches</strong>. For this, we
            have made a small tier list of what we consider to be ideal,
            acceptable or something you should never pick.
          </li>
        </ul>
        <StaticImage
          src="../../../images/nikke/collection/dispatch_3.webp"
          alt="Guide"
        />
        <ul>
          <li>
            While following the resource ruleset above, you can consider how
            many times you want to do gem rerolls without overspending and
            before losing on value.
          </li>
          <li>
            We strongly recommend refreshing dispatches after claiming all gems,
            core dust, and credit boxes and when there are still ≥6 unclaimed
            slots. Hopefully, with this refresh, your doll dispatches improve
            (not guaranteed).
          </li>
          <li>
            What if your doll dispatches were terrible and you had fewer than 6
            open slots? This is where you have to make your own decision, a hard
            one.
          </li>
          <li>
            You can choose to refresh to gamble for better doll dispatches but
            the price:return value for resources is already subpar. Whether that
            is justifiable by itself, that depends on your goal—whether you are
            a value maximizer or a tolerant compromiser.
          </li>
          <li>
            Either way, the holistic analysis considers the average reception,
            so your mileage may vary, but deviations should tighten with
            continued application.
          </li>
        </ul>
        <SectionHeader title="Other guides in the series" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Collection Items - Intro"
            link="/nikke/guides/collection-intro"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_coll.jpg"
                alt="Collection Items - Intro"
              />
            }
          />
          <CategoryCard
            title="Collection Items - Upgrade Optimization"
            link="/nikke/guides/collection-dolls"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_dolls.jpg"
                alt="Collection Items - Upgrade Optimization"
              />
            }
          />
          <CategoryCard
            title="Collection Items - Priority"
            link="/nikke/guides/collection-priority"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_priority.jpg"
                alt="Collection Items - Priority"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesCollectionDispatch;

export const Head: React.FC = () => (
  <Seo
    title="Collection Items - Dispatch | NIKKE | Prydwen Institute"
    description="Guide for the Collection Items system in NIKKE helping you to use the Dispatch feature to speed up your progress."
  />
);
